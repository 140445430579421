import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "WebRTC Smarthome Live Video",
  "path": "/Frequently_Asked_Question/WebRTC_Livevideo_Smarthome/",
  "dateChanged": "2024-01-11",
  "author": "Mike Polinowski",
  "excerpt": "How can I use go2RTC for your Cameras Live Video in Home Assistant, ioBroker and Node-RED?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='WebRTC Smarthome Live Video' dateChanged='2024-01-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use go2RTC for your Cameras Live Video in Home Assistant, ioBroker and Node-RED?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WebRTC_Livevideo_Smarthome/' locationFR='/fr/Frequently_Asked_Question/WebRTC_Livevideo_Smarthome/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "webrtc-smarthome-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#webrtc-smarthome-live-video",
        "aria-label": "webrtc smarthome live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WebRTC Smarthome Live Video`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#webrtc-smarthome-live-video"
        }}>{`WebRTC Smarthome Live Video`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#go2rtc"
            }}>{`go2rtc`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#all-codecs"
                }}>{`All Codecs`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#h264h265-source"
                }}>{`H264/H265 source`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#mjpeg-source"
                }}>{`MJPEG source`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#home-assistant"
            }}>{`Home Assistant`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#webrtc-camera-home-assistant-integration"
                }}>{`WebRTC Camera: Home Assistant Integration`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#iobroker"
            }}>{`ioBroker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red"
            }}>{`Node-RED`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p><strong parentName="p">{`Q`}</strong>{`: How can I use go2RTC for your Cameras Live Video in Home Assistant, ioBroker and Node-RED?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: All HD, Full HD and WQHD INSTAR cameras can be used with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{` to generate a WebRTC stream that can be used in your smarthome dashboard. All you need to do is to run the official `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{` container and configure it to have access to your camera's RTSP stream.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#webrtc-smarthome-live-video"
        }}>{`WebRTC Smarthome Live Video`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#go2rtc"
            }}>{`go2rtc`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#all-codecs"
                }}>{`All Codecs`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#h264h265-source"
                }}>{`H264/H265 source`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#mjpeg-source"
                }}>{`MJPEG source`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#home-assistant"
            }}>{`Home Assistant`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#webrtc-camera-home-assistant-integration"
                }}>{`WebRTC Camera: Home Assistant Integration`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#iobroker"
            }}>{`ioBroker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red"
            }}>{`Node-RED`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "go2rtc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#go2rtc",
        "aria-label": "go2rtc permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`go2rtc`}</h2>
    <p>{`Start by pulling the latest version of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{` container from Docker Hub:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://hub.docker.com/r/alexxit/go2rtc"
        }}>{`Docker Hub`}</a>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`alexxit/go2rtc:latest`}</code>{`: Latest release based on alpine (amd64, 386, arm, arm64) with support hardware transcoding for Intel iGPU and Raspberry`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`alexxit/go2rtc:master`}</code>{`: Latest unstable version based on alpine`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`alexxit/go2rtc:master-hardware`}</code>{`: Latest unstable version based on debian 12 (amd64) with support hardware transcoding for Intel iGPU, AMD GPU and NVidia GPU`}</li>
        </ul>
      </li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull alexxit/go2rtc:master-hardware`}</code></pre></div>
    <p>{`You can use the provided Docker Compose file to run the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{` container. `}<strong parentName="p">{`Note`}</strong>{` that the file contains a path `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/go2rtc`}</code>{` that would store the configuration file in your Linux home directory. Change this directory to something suites your operating system:`}</p>
    <p><em parentName="p">{`docker-compose.yml`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`services:
  go2rtc:
    image: alexxit/go2rtc:master-hardware
    network_mode: `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{`       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# important for WebRTC, HomeKit, UDP cameras`}</span>{`
    privileged: `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`         `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# only for FFmpeg hardware transcoding`}</span>{`
    restart: unless-stopped  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# autorestart on fail or config change from WebUI`}</span>{`
    environment:
      - `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Asia/Hong_Kong `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# timezone in logs`}</span>{`
    volumes:
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"~/go2rtc:/config"`}</span>{`   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# folder for go2rtc.yaml file (edit from WebUI)`}</span></code></pre></div>
    <p>{`Execute the file using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` which requires `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/"
      }}>{`Docker`}</a>{` and `}<a parentName="p" {...{
        "href": "https://docs.docker.com/compose/install/"
      }}>{`Docker Compose`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker-compose up -d`}</code></pre></div>
    <p>{`The web interface can now be accessed through your browser on the following URL: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:1984/`}</code>{`. Navigate to the configuration tab and add the RTSP paths for all your `}<a parentName="p" {...{
        "href": "/en/Aussenkameras/IN-5907_HD/Video_Streaming/"
      }}>{`HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Aussenkameras/IN-9008_HD/Video_Streaming/"
      }}>{`Full HD`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Aussenkameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`WQHD`}</a>{` cameras. The configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc.yaml`}</code>{` will be stored in the directory you have chosen in the docker-compose file:`}</p>
    <p><em parentName="p">{`go2rtc.yaml`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`streams`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`8415`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.125`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/livestream/11
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.125`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/livestream/12
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.125`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/livestream/13
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`8015`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.15`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/11
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.15`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/12
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.15`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/13
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9408`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.115/livestream/11
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.115/livestream/12
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.115/livestream/13
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9420`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.56/livestream/11
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.56/livestream/12
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ffmpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rstp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.56/livestream/13`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b15738f57feb29e15c07b02d8d11267e/6d2da/go2rtc_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAmElEQVQY043LWw6CMBBA0e5/USbGmhJKMAFFKQy0KqNCEeURPgyOS7An9/eyIvSVx0EKkEJ5G5DC7IKuzJ9F9jd20aXW2hSQQ6YypVR6Mfozzy4xGYQrzj3fF4Fcb/kJoJ+mZVnIAUuSJIoP8THfp6WM1BkfRPQlJ6yq8N60aN/Yjtd2arrB+SWGiLfaou0rO5p6sK+RnP0AEVBRWCEdTzoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b15738f57feb29e15c07b02d8d11267e/e4706/go2rtc_01.avif 230w", "/en/static/b15738f57feb29e15c07b02d8d11267e/d1af7/go2rtc_01.avif 460w", "/en/static/b15738f57feb29e15c07b02d8d11267e/7f308/go2rtc_01.avif 920w", "/en/static/b15738f57feb29e15c07b02d8d11267e/1d2c7/go2rtc_01.avif 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b15738f57feb29e15c07b02d8d11267e/a0b58/go2rtc_01.webp 230w", "/en/static/b15738f57feb29e15c07b02d8d11267e/bc10c/go2rtc_01.webp 460w", "/en/static/b15738f57feb29e15c07b02d8d11267e/966d8/go2rtc_01.webp 920w", "/en/static/b15738f57feb29e15c07b02d8d11267e/40732/go2rtc_01.webp 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b15738f57feb29e15c07b02d8d11267e/81c8e/go2rtc_01.png 230w", "/en/static/b15738f57feb29e15c07b02d8d11267e/08a84/go2rtc_01.png 460w", "/en/static/b15738f57feb29e15c07b02d8d11267e/c0255/go2rtc_01.png 920w", "/en/static/b15738f57feb29e15c07b02d8d11267e/6d2da/go2rtc_01.png 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b15738f57feb29e15c07b02d8d11267e/c0255/go2rtc_01.png",
              "alt": "go2rtc",
              "title": "go2rtc",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Changing to the `}<strong parentName="p">{`Streams`}</strong>{` tab you should now be able to see all your cameras. And clicking on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`links`}</code>{` link leads you to a collection of all URLs to available video streams that are generated by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{`:`}</p>
    <h3 {...{
      "id": "all-codecs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#all-codecs",
        "aria-label": "all codecs permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All Codecs`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://127.0.0.1:1984/stream.html?src=8415"
        }}>{`stream.html`}</a>{` with auto-select mode / browsers: all / codecs: H264, H265*, MJPEG, JPEG, AAC, PCMU, PCMA, OPUS`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "rtsp://127.0.0.1:8554/8415"
        }}>{`rtsp`}</a>{` rtsp with only one video and one audio / codecs: any`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "rtsp://127.0.0.1:8554/8415?mp4"
        }}>{`rtsp`}</a>{` for MP4 recording (Hass or Frigate) / codecs: H264, H265, AAC`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "rtsp://127.0.0.1:8554/8415?video=all&audio=all"
        }}>{`rtsp`}</a>{` with all tracks / codecs: any ffplay -fflags nobuffer -flags low_delay -rtsp_transport tcp "rtsp://127.0.0.1:8554/8415"`}</li>
    </ul>
    <h3 {...{
      "id": "h264h265-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#h264h265-source",
        "aria-label": "h264h265 source permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`H264/H265 source`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "stream.html?src=8415&mode=webrtc"
        }}>{`stream.html`}</a>{` WebRTC stream / browsers: all / codecs: H264, PCMU, PCMA, OPUS / +H265 in Safari`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "stream.html?src=8415&mode=mse"
        }}>{`stream.html`}</a>{` MSE stream / browsers: Chrome, Firefox, Safari Mac/iPad / codecs: H264, H265`}<em parentName="li">{`, AAC, PCMA`}</em>{`, PCMU`}<em parentName="li">{`, PCM`}</em>{` / +OPUS in Chrome and Firefox`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.mp4?src=8415"
        }}>{`stream.mp4`}</a>{` legacy MP4 stream with AAC audio / browsers: Chrome, Firefox / codecs: H264, H265*, AAC`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.mp4?src=8415&mp4=flac"
        }}>{`stream.mp4`}</a>{` modern MP4 stream with common audio / browsers: Chrome, Firefox / codecs: H264, H265*, AAC, FLAC (PCMA, PCMU, PCM)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.mp4?src=8415&mp4=all"
        }}>{`stream.mp4`}</a>{` MP4 stream with any audio / browsers: Chrome / codecs: H264, H265*, AAC, OPUS, MP3, FLAC (PCMA, PCMU, PCM)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/frame.mp4?src=8415"
        }}>{`frame.mp4`}</a>{` snapshot in MP4-format / browsers: all / codecs: H264, H265*`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.m3u8?src=8415"
        }}>{`stream.m3u8`}</a>{` legacy HLS/TS / browsers: Safari all, Chrome Android / codecs: H264`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.m3u8?src=8415&mp4"
        }}>{`stream.m3u8`}</a>{` legacy HLS/fMP4 / browsers: Safari all, Chrome Android / codecs: H264, H265*, AAC`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.m3u8?src=8415&mp4=flac"
        }}>{`stream.m3u8`}</a>{` modern HLS/fMP4 / browsers: Safari all, Chrome Android / codecs: H264, H265*, AAC, FLAC (PCMA, PCMU, PCM)`}</li>
    </ul>
    <h3 {...{
      "id": "mjpeg-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mjpeg-source",
        "aria-label": "mjpeg source permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MJPEG source`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "stream.html?src=8415&mode=mjpeg"
        }}>{`stream.html`}</a>{` with MJPEG mode / browsers: all / codecs: MJPEG, JPEG`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/stream.mjpeg?src=8415"
        }}>{`stream.mjpeg`}</a>{` MJPEG stream / browsers: all / codecs: MJPEG, JPEG`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "api/frame.jpeg?src=8415"
        }}>{`frame.jpeg`}</a>{` snapshot in JPEG-format / browsers: all / codecs: MJPEG, JPEG`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2d6b249869b56c029d464cbae1eacbaf/07d7d/go2rtc_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABc0lEQVQY0zXOS3KiQACAYSoxi4iUYpEJRBxH2m77QRpamgZaHhqSybicrNy70Cu48QaeyaN4lVQmNZt/91X9Rq5LkeokL3Xdluu3ZfOa5CVP8jgtpjP8NBoHIJgTBiDGjPM4gZjOEAkgHvljYwaREIssy9YvL0plEEKZSs4jx3Ee3VHP6vcHdla/ynJdrN6q9o+q2mS5JpHsdO6Mn+MxQohStlo1WmvO+Xa73e12nus6D65lDe67vaioeV6FakllQWWOhJri8Obm1gAATCYTx3GKotjv98fj8Xq9Xi4X27Yffnj/sJnoRuom1c1zklORUqGCb0wIBQAMh8PNZnM+n+u6PhwOVVWZZtf1vrbvuyaLUxpJGqckkigUmCcBYl8YY4IxGfn+7/f30+kEAPj78dG2rT2wXc+3rH7X7JE4nYcCshj97y9Ibjt3RqZ0rDRfZFzmoVAkSmgs50xQmUzR3HP9KYBMKBqn35IJtShqJtJH9+kTfs5NNawb4OEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d6b249869b56c029d464cbae1eacbaf/e4706/go2rtc_02.avif 230w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/d1af7/go2rtc_02.avif 460w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/7f308/go2rtc_02.avif 920w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/e1c99/go2rtc_02.avif 1380w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/0a5c9/go2rtc_02.avif 1478w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2d6b249869b56c029d464cbae1eacbaf/a0b58/go2rtc_02.webp 230w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/bc10c/go2rtc_02.webp 460w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/966d8/go2rtc_02.webp 920w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/445df/go2rtc_02.webp 1380w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/9bf70/go2rtc_02.webp 1478w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d6b249869b56c029d464cbae1eacbaf/81c8e/go2rtc_02.png 230w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/08a84/go2rtc_02.png 460w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/c0255/go2rtc_02.png 920w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/b1001/go2rtc_02.png 1380w", "/en/static/2d6b249869b56c029d464cbae1eacbaf/07d7d/go2rtc_02.png 1478w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2d6b249869b56c029d464cbae1eacbaf/c0255/go2rtc_02.png",
              "alt": "go2rtc for Home Assistant",
              "title": "go2rtc for Home Assistant",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now continue using those URLs to add the video stream to our smarthome web user interface of choice.`}</p>
    <h2 {...{
      "id": "home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-assistant",
        "aria-label": "home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant`}</h2>
    <h3 {...{
      "id": "webrtc-camera-home-assistant-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#webrtc-camera-home-assistant-integration",
        "aria-label": "webrtc camera home assistant integration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WebRTC Camera: Home Assistant Integration`}</h3>
    <p>{`Start by installing the `}<strong parentName="p">{`WebRTC Camera Add-on`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/72d4cd2674421ad5323c62925bb6c322/636d3/go2rtc_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABXUlEQVQoz4XRy3KjMBAFUDaZqgAGJIMltYRerQdyCHF28/9fNoVdJJmaVObsr7r7qkLEci2lFGPMZZokgFJKCEEpJf9TIWJMSUoJAM45j2jvhmFomqb9UZVyxhCUUgDyZclvyTPOlVJd17Vt232nP1QRfU5RSQmCr2/v83L79fRECOGcM8bGcaT/+Jzs1psrq3QBjFXAhLiwy4QhlFKWZbHWKqXmg9baGHM+nwkhTdNU9vZbv9xkLICJMca54JxrrUMI1loAkAcAsNYiIiHkUWflrbFa39cGyajm/dD33vvt9TXnnP6Wc7bWPqoehqHa39wXU3t27Ip6pl3tfdi2PfxVKSXd/6Vt2+auOs55hPssazXVIeC2bdfrdV3XGGM4pBD0PH8W9jhGSilA8rGPoo5QWy2d3yGi1vqjsNlHmM3pdISNMTFG5xwXAFMPY3M6tZTS8TB9JeR5unxM/gMQu0HZICmKewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72d4cd2674421ad5323c62925bb6c322/e4706/go2rtc_07.avif 230w", "/en/static/72d4cd2674421ad5323c62925bb6c322/d1af7/go2rtc_07.avif 460w", "/en/static/72d4cd2674421ad5323c62925bb6c322/7f308/go2rtc_07.avif 920w", "/en/static/72d4cd2674421ad5323c62925bb6c322/ea3f7/go2rtc_07.avif 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/72d4cd2674421ad5323c62925bb6c322/a0b58/go2rtc_07.webp 230w", "/en/static/72d4cd2674421ad5323c62925bb6c322/bc10c/go2rtc_07.webp 460w", "/en/static/72d4cd2674421ad5323c62925bb6c322/966d8/go2rtc_07.webp 920w", "/en/static/72d4cd2674421ad5323c62925bb6c322/41bb6/go2rtc_07.webp 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72d4cd2674421ad5323c62925bb6c322/81c8e/go2rtc_07.png 230w", "/en/static/72d4cd2674421ad5323c62925bb6c322/08a84/go2rtc_07.png 460w", "/en/static/72d4cd2674421ad5323c62925bb6c322/c0255/go2rtc_07.png 920w", "/en/static/72d4cd2674421ad5323c62925bb6c322/636d3/go2rtc_07.png 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/72d4cd2674421ad5323c62925bb6c322/c0255/go2rtc_07.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Restart Home Assistant and you should now be able to add the `}<strong parentName="p">{`WebRTC Camera Integration`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/e3189/go2rtc_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA/ElEQVQY01WQ4W6DMAyEeYJRbJI4dkggBAVYC9W2Tn3/J5vaVBX7dLLkH3dnudJaW2bvfR+C1lophYhN0wAAEIMEbBWzVUoRUZlEhIht21byhK0tO2nlRPoQgvckTrMzlonIEJG1bxs+qYwhZjZGAyIC1OSGvOzX67rtcUyh6/rgH/I+DkPfB3jyMouIZX6FQVPbzufz9vU77d/jepmWz6I0r/m89WMqzYXKGMP8OKzkfTSQ1/n2sw9TjlOWMBS5EMe8iPdwoOq6TkSMMY/mU32at+V+v+05pWlKoxN5K3jvRPBA5ZyzzOXJiFBDm3LaL3mIY4xRRNwB+W/+A75sK+g6qGhKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0da26e1d7c3ecde82c1efb383b38fb72/e4706/go2rtc_08.avif 230w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/d1af7/go2rtc_08.avif 460w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/7f308/go2rtc_08.avif 920w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/eeaf8/go2rtc_08.avif 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0da26e1d7c3ecde82c1efb383b38fb72/a0b58/go2rtc_08.webp 230w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/bc10c/go2rtc_08.webp 460w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/966d8/go2rtc_08.webp 920w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/77d2c/go2rtc_08.webp 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0da26e1d7c3ecde82c1efb383b38fb72/81c8e/go2rtc_08.png 230w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/08a84/go2rtc_08.png 460w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/c0255/go2rtc_08.png 920w", "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/e3189/go2rtc_08.png 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0da26e1d7c3ecde82c1efb383b38fb72/c0255/go2rtc_08.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can add the API URL from your `}<strong parentName="p">{`go2rtc`}</strong>{` container:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0478229b246259085a8e18cdfb0bc7f1/b94e3/go2rtc_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAz0lEQVQY023M0WqEMBCFYa9jwZpJwGQ2bmycTGJwlZXCvv+TFRVbWvoxl/OfyhiDiFprKSUAEFEkstYau0NEi2isDSF477XWbdvKAwBUiGiMUUoBgFKKIg+50GEcR+dcv9+tP3Rdd74ppbTWe2ytPWOQ8iOEz9dr27ZSyrIsj2VNy5MeG6Vyv/da66Zp3i/VOQOXYRhKmXLOMcbEzJyIM6XMnLz3UkohRH2p4LdpmtZ1neeZmeMP2rdSVkoJId4uf2NEdM7d/hVYAtR1/R1/AcTjJe5ExOw/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0478229b246259085a8e18cdfb0bc7f1/e4706/go2rtc_09.avif 230w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/d1af7/go2rtc_09.avif 460w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/7f308/go2rtc_09.avif 920w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/bedb6/go2rtc_09.avif 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0478229b246259085a8e18cdfb0bc7f1/a0b58/go2rtc_09.webp 230w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/bc10c/go2rtc_09.webp 460w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/966d8/go2rtc_09.webp 920w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/65db1/go2rtc_09.webp 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0478229b246259085a8e18cdfb0bc7f1/81c8e/go2rtc_09.png 230w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/08a84/go2rtc_09.png 460w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/c0255/go2rtc_09.png 920w", "/en/static/0478229b246259085a8e18cdfb0bc7f1/b94e3/go2rtc_09.png 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0478229b246259085a8e18cdfb0bc7f1/c0255/go2rtc_09.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit your dashboard and add a `}<strong parentName="p">{`Custom: WebRTC Camera`}</strong>{` card with one of the following configurations:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/7b1dc/go2rtc_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABBUlEQVQY043QS07DMBAG4AhVCDWtnbTBOPPI2KkTqyQiYsGOo3AB9qw4Azfgsqg2lLLjW1jWPzOWxgUiAkAIYZomIsKEmfP9eDw+JPM8i0guKaU2SdElIRGRruuY2VqLiCIyDEOMcRzHGKNzDgDattVal2V5GiaiEIL33jnnE2PMfr9vmgYR+753ifdeRJqmMcb8DkPS/tX3PRFZa88J/CAipdT3cK7lZTIiWpblcDgQ0Tm5fEVpXW43m+22qOu6qipjDDMjYv6n3JQTZgaAqqpOnVobc6vWN+vVVXm9Ou2cBwDgfGaXSW4jccTdLj7uluf6/qnI5X9q/WCR717eu7dPfP34AsxAM+89Il+yAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/e4706/go2rtc_10.avif 230w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/d1af7/go2rtc_10.avif 460w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/7f308/go2rtc_10.avif 920w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/85cc3/go2rtc_10.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/a0b58/go2rtc_10.webp 230w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/bc10c/go2rtc_10.webp 460w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/966d8/go2rtc_10.webp 920w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/409e5/go2rtc_10.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/81c8e/go2rtc_10.png 230w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/08a84/go2rtc_10.png 460w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/c0255/go2rtc_10.png 920w", "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/7b1dc/go2rtc_10.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f3f3ea0f73108ccd2b33fa0b983e31cf/c0255/go2rtc_10.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`type: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'custom:webrtc-camera'`}</span>{`
url: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rtsp://rtsp:12345678@192.168.1.123:554/av_stream/ch0'`}</span>{`

---

type: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'custom:webrtc-camera'`}</span>{`
url: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'camera1'`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# stream name from go2rtc.yaml`}</span>{`

---

type: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'custom:webrtc-camera'`}</span>{`
entity: camera.generic_stream  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# change to your camera entity_id`}</span></code></pre></div>
    <p>{`Since I already added cameras to `}<strong parentName="p">{`go2RTC`}</strong>{` I will simply use the ID that I assigned to them there:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60b659e1548b9862b4113d8a82302d7b/73caa/go2rtc_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABg0lEQVQoz23Ry46CMBQGYPeaAdrSlt6kWAoiCGYciRB9ABe+hVtd+joufcyJ1HEumW/RNG3+nuacEY0iTAjjfPZgnCzP5vNCJ4mUkguRJLNE66maJrOZVApA+OZ5b5434pxRSpVS1tp0YIyx1uZ5rrVmjPHfhBAIofF4PJlMRtEDS9KsKuZlVS4G1lpjTJqmWZZZa7PBaxOG4Xc4DMO8rNuP9/XHe9M0dV0vl8uqqsry+dZisSh/wBg/w5xzQohUSj/EWmtXM03TOI7dP6WUURTRQRRFEMJnGA8AAP4AALBardq27fu+bdu6rruuy/OcEOL7fvBlMhgBABBCEEJ3CgBomqYsy81mczgcTqfT/X7f7/cAgOCHZxhC+Ce8Xq+NMX3fXy6X6/V6u926rsMY/1MZIYQx/lPZWrvdbs/n8263Ox6PRVFQSn3PCwL/V1gIoZRyjRFCuMG6/fSLlJI9LgQTkksFX3N2DWOMKaWEEK+uUkoJIa+VUkK5IDKmYhoEgQt/AsvuUg0eV1Y0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60b659e1548b9862b4113d8a82302d7b/e4706/go2rtc_11.avif 230w", "/en/static/60b659e1548b9862b4113d8a82302d7b/d1af7/go2rtc_11.avif 460w", "/en/static/60b659e1548b9862b4113d8a82302d7b/7f308/go2rtc_11.avif 920w", "/en/static/60b659e1548b9862b4113d8a82302d7b/a53e8/go2rtc_11.avif 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60b659e1548b9862b4113d8a82302d7b/a0b58/go2rtc_11.webp 230w", "/en/static/60b659e1548b9862b4113d8a82302d7b/bc10c/go2rtc_11.webp 460w", "/en/static/60b659e1548b9862b4113d8a82302d7b/966d8/go2rtc_11.webp 920w", "/en/static/60b659e1548b9862b4113d8a82302d7b/f9357/go2rtc_11.webp 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60b659e1548b9862b4113d8a82302d7b/81c8e/go2rtc_11.png 230w", "/en/static/60b659e1548b9862b4113d8a82302d7b/08a84/go2rtc_11.png 460w", "/en/static/60b659e1548b9862b4113d8a82302d7b/c0255/go2rtc_11.png 920w", "/en/static/60b659e1548b9862b4113d8a82302d7b/73caa/go2rtc_11.png 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60b659e1548b9862b4113d8a82302d7b/c0255/go2rtc_11.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To verify that it is working you can check the `}<strong parentName="p">{`go2RTC`}</strong>{` dashboard to check if the camera now list one online stream consumer:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "864px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b1e857b3996faa96f640d196f92c828/9cab2/go2rtc_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAsElEQVQY043L6wqCMABA4b3/4wSGRRcRRhRCUKm7pZYwtXbJUIxEFz2B+zh/Dwj9NTtAvPPpHsbQY3vIDlAypK54MnAn6JGlOSU8YRmKcoqrW9q9dKfVZOAcoSAKY0KOGAXh5ZRcb0XRf3tjASxc15m7G89frrYzx8WEGmOGYRgtACHkUzeVaqr6w3Un69aY0dgB9zzP+DPjIi1rynUpa2MNCCke6v1Pt4VqXu/Wfv4BQxWJDzzqJukAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b1e857b3996faa96f640d196f92c828/e4706/go2rtc_12.avif 230w", "/en/static/9b1e857b3996faa96f640d196f92c828/d1af7/go2rtc_12.avif 460w", "/en/static/9b1e857b3996faa96f640d196f92c828/2bbfa/go2rtc_12.avif 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b1e857b3996faa96f640d196f92c828/a0b58/go2rtc_12.webp 230w", "/en/static/9b1e857b3996faa96f640d196f92c828/bc10c/go2rtc_12.webp 460w", "/en/static/9b1e857b3996faa96f640d196f92c828/b4e26/go2rtc_12.webp 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b1e857b3996faa96f640d196f92c828/81c8e/go2rtc_12.png 230w", "/en/static/9b1e857b3996faa96f640d196f92c828/08a84/go2rtc_12.png 460w", "/en/static/9b1e857b3996faa96f640d196f92c828/9cab2/go2rtc_12.png 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b1e857b3996faa96f640d196f92c828/9cab2/go2rtc_12.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And your cameras are now running via the `}<strong parentName="p">{`MSE stream`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7705217f63db23e8a96ac65cc17cca1f/8802b/go2rtc_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABY0lEQVQY0zXCu07CUBgA4L6A2JTeKOfnUs4p2pbT1hJrHTRRGCQQdWFhYBANCSaaGBNwcPKWYBx0E8ULIBUCDpj4eg7GLx+TUPGCYRqUEk0LQ5KNpVhQWVC5OJ5HKgupcILMoyQLajhB/sp4MUJ0PqkxiuVHqacsWrJm6NRys9llz1vxfTOTodSybBsgFkex01bz9uHu4ubyun3Vvr/1V1clSWRSGBNNQwBKNGqapuu6juPkc7n19bXDRqN11uJ5fsXzhtNxb9h7H7y99V+m39Ojk2NBFBhCCMYYABRFoZTquo4QqlQq9Xp9NpsFQSDL8sbm5mAy6nSfnl+fO93Hj/GwdX7GCwKDMdZ1HQAikYjrurZtE0KazWatVqtWq+VyGRByl7Lv48+Pyagf9HtB/+tntnewPxcKMel0mhCC/gGAJEm+7zuOYxgGpVQURZEXN/K50u7OVrFQKBVLu9uYEI7jfgGFb14SXK4PywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7705217f63db23e8a96ac65cc17cca1f/e4706/go2rtc_13.avif 230w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/d1af7/go2rtc_13.avif 460w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/7f308/go2rtc_13.avif 920w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/d5bb4/go2rtc_13.avif 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7705217f63db23e8a96ac65cc17cca1f/a0b58/go2rtc_13.webp 230w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/bc10c/go2rtc_13.webp 460w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/966d8/go2rtc_13.webp 920w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/5375b/go2rtc_13.webp 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7705217f63db23e8a96ac65cc17cca1f/81c8e/go2rtc_13.png 230w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/08a84/go2rtc_13.png 460w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/c0255/go2rtc_13.png 920w", "/en/static/7705217f63db23e8a96ac65cc17cca1f/8802b/go2rtc_13.png 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7705217f63db23e8a96ac65cc17cca1f/c0255/go2rtc_13.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "iobroker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker",
        "aria-label": "iobroker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker`}</h2>
    <p>{`You can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`stream.html`}</code>{` (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.112:1984/stream.html?src=8415`}</code>{`) stream provided in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`go2rtc`}</code>{` web interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/b04e4/go2rtc_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA3klEQVQY042P3U7DMAxG8/5vhQZl7B7KuiU0mdq13WjzW8dJqqKGGy5A4siyrU86kk3WfxC9Pz7vjvvd6eXpdCjK4uF8KKr9I7HWWOe01s45Ywwi/uovMS4xpBjyslUKgVzbVtSXD1Y3TcMou9/uS0oeMcSIiADgvfe5A2IIAcBjDmJKZNLh0hhRN4xRzgWjlHM+DMM4TlopvaGklCpjrFU5lFICAOlUeD3z9/KtqqqyLBmlQoh24/rt/PXIuq5kMtDfPvvu2vdD13XjOLqN2Vqb5wwA6PPt87yk5af8BevSVCLnm/ajAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ffe583cb0cb4373a20334dd5cd1b421/e4706/go2rtc_14.avif 230w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/d1af7/go2rtc_14.avif 460w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/dea7a/go2rtc_14.avif 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ffe583cb0cb4373a20334dd5cd1b421/a0b58/go2rtc_14.webp 230w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/bc10c/go2rtc_14.webp 460w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/60055/go2rtc_14.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ffe583cb0cb4373a20334dd5cd1b421/81c8e/go2rtc_14.png 230w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/08a84/go2rtc_14.png 460w", "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/b04e4/go2rtc_14.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ffe583cb0cb4373a20334dd5cd1b421/b04e4/go2rtc_14.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use an iFrame Widget to add the stream to your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vis`}</code>{` dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/82c1e/go2rtc_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB10lEQVQoz2MwN7G0NLeJT0pOSEoK8PPTVlHV0FBV01QTExO1sLR0dXV1dnCyNLN0sHHQUtdSVVZVU1GDI4b0nEIVVfW81KTK0pLm2ppgR5fomMjw+PCouKgNGzdNnz7N09XD2cHZ1dHVwcbByc7Jyd7Zyd7Jyc7J0daRYc3OA6Ji4kmxYa1tdZ31FWE+XoERke5+njkFees3bmpuaZaWkubm4mZiYpKXU9TS1JWTVZCXV5KVkZcQl2Iorqjm5xf0CQ5LyMjy8QlycPTy9A9y9nRzcnOZP39hXX2dhIS4iIgIIyMDFyc3IyMTAzKYv2iJkJCQtJSCtraBqZmVvoGxiqqata21mrqarKyskrKysLAwOzs7MzMzj4AgCysrCxJgCCvrYuMTVlKQ09LWsrOztba2MjU1zc3NDQ8PFxISkpKUDPIL9HTzdHF0cXFydXZwQUYMEXXTOAREFeRkxcTErayskpOTa2pqHj582NPTw8PDo6igmBATFx8VG+QX5Ovp6+fph4wYXKvm8IpIqKooy8jK+vv719XV6ejoZGZm2tjY8PPzy8nKuTo621rbmhmbWZhaoCGGpOpufiEROTlZGRkZJyenyspKISGhpKQka2trfhAQYMANAL1bevfKuPf9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d0f6acd1d840559c0a8126d4a910b7e/e4706/go2rtc_15.avif 230w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/d1af7/go2rtc_15.avif 460w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/7f308/go2rtc_15.avif 920w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/e1c99/go2rtc_15.avif 1380w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/00638/go2rtc_15.avif 1398w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d0f6acd1d840559c0a8126d4a910b7e/a0b58/go2rtc_15.webp 230w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/bc10c/go2rtc_15.webp 460w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/966d8/go2rtc_15.webp 920w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/445df/go2rtc_15.webp 1380w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/b391b/go2rtc_15.webp 1398w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d0f6acd1d840559c0a8126d4a910b7e/81c8e/go2rtc_15.png 230w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/08a84/go2rtc_15.png 460w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/c0255/go2rtc_15.png 920w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/b1001/go2rtc_15.png 1380w", "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/82c1e/go2rtc_15.png 1398w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d0f6acd1d840559c0a8126d4a910b7e/c0255/go2rtc_15.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red",
        "aria-label": "node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED`}</h2>
    <p>{`And the same also works in Node-RED using iFrame UI Node:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-node-ui-iframe"
        }}>{`node-red-node-ui-iframe`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "855px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/387134a2b0dc0f0a7427c36d3ca71568/77800/go2rtc_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABrklEQVQoz22RS2/TQBSF/RebJlQR7QL+VzfsgCBSQLSJY49flJ0dx0EsYOFQIto0mbFjz4znYQ+yYyKD+HR0790cnXtntOcXF0/Pz4fDYa/XO2nUOz2t55N67P+XwWBwdtbv9zXbth3HcV0XAGDqMw+YjgXCxSKKouVyGcfxer3++Td3q9Xq2/dVHGsfXW8MLPP285counbsFx/ej00DIcQYK4qC/gMhQqlfzq335NmDH2pjYI1mxsT1FvO57rkv9enEsbbbRwjhbreTUpZleaxSSiHl4/399sddAqF2Zdkjw9S9T0EQRGH4NYqiMEQIEUJEA6UUY8w5LxuklIzzSinOuTa26uSp582DwG8UBAGEMM/zqqoYKwjBlFIhhKpapJSqNQNrpBtTtzYfgRBijJVSlDJCCiFLxkXBRMEFa9QmvzXBa302cdyuGSF0SMa0QOk+3efpPkdpluzzWmkmZCU4197VybNpx+z7fpIkhJA/71TWrVm4rWXZrv3GBK8Mc9JZ2/f9NE0ZY83NLMuywwlVB6UUY0y7vL65vJlc2XbXvNlsjv90oOs8JGOMfwOuiSklzcj6ywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/387134a2b0dc0f0a7427c36d3ca71568/e4706/go2rtc_16.avif 230w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/d1af7/go2rtc_16.avif 460w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/09f2b/go2rtc_16.avif 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/387134a2b0dc0f0a7427c36d3ca71568/a0b58/go2rtc_16.webp 230w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/bc10c/go2rtc_16.webp 460w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/31c30/go2rtc_16.webp 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/387134a2b0dc0f0a7427c36d3ca71568/81c8e/go2rtc_16.png 230w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/08a84/go2rtc_16.png 460w", "/en/static/387134a2b0dc0f0a7427c36d3ca71568/77800/go2rtc_16.png 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/387134a2b0dc0f0a7427c36d3ca71568/77800/go2rtc_16.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "772px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/940c5/go2rtc_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACaElEQVQoz1WP3UrbYBzGe6aI2nw1Mc3X23y8aZK2Sdq8Sds0TbpaWapspUwHAfEKvIiuoOjBxlgvYFTGyi7Bw20HHnjsqeCp4C04VmWyh9/RH57nxz/3ce/b573Fl/GPT8PvZ/H8PLlYMj9P5mfJ/PnSuziL50+cJ/PT6OsTOddoDKM3cfAqRFHo/UcHdbvNpN3otOph5McdP+6gbuhFMqcWKZ4rCDlJlmzXLpu6pqtlUzcrhlWzjIphVc2ypVM0qZswaPkCEBQoAwW4DYdiqPX8+ga2kZNlpVarOY6LPNRqtpIk6b/qIw9pqsbQjNfwsizbf7dPkRRO4HSBlkSJLtA4hhM4kZMkyTRN27aDINje3h6NRr1eT9M0AMBgMBiPx8PhsN/vsyzLcVyxWMRxnCRJfJmcIIo6hAzD8DxvGAYA4PDwsF6vt9vtnZ2dKIqyLJvNZr7vS5KkaRqO4xRFPZdBqeQ4Do7jxWIRLldms1mz2UzTdDKZXF5ePjw8PD4+Hh0dkSSp6zqGYS/mkizbtr25uSmKYqVSsSxrsViEYTidTm9vb+/v7+/u7q6vr9M0XVlZkSSJJMkXs6qqjuPk83mO4yCE5XL56uoqTdMgCOI4dl33+Ph4NBpVq9XV1VWwDEEQz2Vd132ESIIQeF5VFB3Cm5ubvd1dhqHrddeyrNOTkyzLeJ5fW1sDAPA8/2I2DKMdhgzLCqKkQliSlZ+/fr8eDpktNorjhocmH6b7BwcAgKfXWJalaRrDsL9lSRRhpVqoIrqGtmyfqaHm2/elZkIYruB1BK8DuzuoNyjrUFFUhJDv+//MfwBtdaMrPrDutgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fade1f0c3d5af87bbf9272a050ee8c42/e4706/go2rtc_17.avif 230w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/d1af7/go2rtc_17.avif 460w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/41704/go2rtc_17.avif 772w"],
              "sizes": "(max-width: 772px) 100vw, 772px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fade1f0c3d5af87bbf9272a050ee8c42/a0b58/go2rtc_17.webp 230w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/bc10c/go2rtc_17.webp 460w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/ca172/go2rtc_17.webp 772w"],
              "sizes": "(max-width: 772px) 100vw, 772px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fade1f0c3d5af87bbf9272a050ee8c42/81c8e/go2rtc_17.png 230w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/08a84/go2rtc_17.png 460w", "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/940c5/go2rtc_17.png 772w"],
              "sizes": "(max-width: 772px) 100vw, 772px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fade1f0c3d5af87bbf9272a050ee8c42/940c5/go2rtc_17.png",
              "alt": "Home Assistant with RTSPtoWeb",
              "title": "Home Assistant with RTSPtoWeb",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      